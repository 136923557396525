.mat-mdc-progress-bar, .mat-mdc-progress-bar.mat-accent, .mat-mdc-progress-bar.mat-warn {
  .mdc-linear-progress__buffer-bar {
    background: #e2e2e2;
  }
}

.vex-style-dark {
  .mat-mdc-progress-bar, .mat-mdc-progress-bar.mat-accent, .mat-mdc-progress-bar.mat-warn {
    .mdc-linear-progress__buffer-bar {
      background: rgba(255, 255, 255, 0.5);
    }
  }
}
