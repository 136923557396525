:root {
  .mat-mdc-select-panel {
    @apply rounded shadow p-2;
  }

  .mat-mdc-option {
    @apply rounded text-sm font-medium h-10 transition duration-100 ease-out;

    &:hover, &.mat-active {
      @apply bg-primary/10;

      .mat-icon {
        @apply text-primary;
      }
    }

    .mat-icon {
      @apply transition duration-100 ease-out;
      font-size: 1.25rem;
      height: 1.25rem;
      width: 1.25rem;
    }

    &.mat-mdc-selected:not(.mat-mdc-option-multiple) {
      @apply bg-primary/20;
    }
  }

  .mat-mdc-menu-item-submenu-trigger {
    @apply pr-12;
  }
}
